<template>
<!-- 订单详情 下单后跳转 -->
  <div class="Details">
    <van-nav-bar
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <input type="text"> -->
    <div class="topTitle" v-if="orderData.officialAccountName">
      <div class="popupTitle">
        <span>公众号名称：</span>
        <input type="text" id="code" v-model="orderData.officialAccountName" disabled>
      </div>
      <button type="button"
        class="topButton"
        v-clipboard:copy="orderData.officialAccountName"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError">点击复制</button>
    </div>
    <ul class="wechat" v-if="orderData.officialAccountImage">
      <li class="imgWrapper">
        <img :src="orderData.officialAccountImage" alt="" class="wxImg">
      </li>
      <li class="content">
        <p style="color: red">请一定要保存二维码哟！！！</p>
        <p>微信扫一扫关注公众号</p>
        <p>方便查询<span style="color: #333; font-weight: bold">订单进度</span></p>
      </li>
    </ul>
    <ul class="orderWrapper" v-if="this.orderData">
      <li class="orderList" style="justify-content: center;align-items: center; margin-top: 0">
        <img src="../../assets/CG.png" alt="" style="width: 51px;height: 51px; margin-right: 20px">
        <p style="color: #333; font-size: 14px; font-weight: bold">领取成功，我们将于24小时内为您发货！</p>
      </li>
      <!-- <li class="orderList" style="justify-content: center">
        <p class="">{{ this.$route.meta.title }}</p>
      </li> -->
    </ul>
    <ul class="orderWrapper" v-if="this.orderData">
      <li class="orderList">
        <span class="navTitle">商品名称：</span>
        <span class="navContent">{{ orderData.goodsName }}</span>
      </li>
      <li class="orderList">
        <span class="navTitle">订单号：</span>
        <span class="navContent">{{ this.$route.params.No }}</span>
      </li>
      <li class="orderList">
        <span class="navTitle">下单时间：</span>
        <span class="navContent">{{ orderData.orderTime }}</span>
      </li>
      <li class="orderList">
        <span class="navTitle">数量：</span>
        <span class="navContent">{{ orderData.orderCount }}</span>
      </li>
      <li class="orderList">
        <span class="navTitle">价格：</span>
        <span class="navContent price">¥{{ orderData.orderTotal / 100 }}</span>
      </li>
    </ul>
    <ul class="orderWrapper" v-if="this.orderData">
      <li class="orderList">
        <span class="navTitle">联系人：</span>
        <span class="navContent">{{ orderData.deliveryName }}</span>
      </li>
      <li class="orderList">
        <span class="navTitle">联系电话：</span>
        <span class="navContent">{{ orderData.deliveryMobile }}</span>
      </li>
      <li class="orderList">
        <span class="navTitle">配送地址：</span>
        <span class="navContent">{{ orderData.deliveryAddress }}</span>
      </li>
    </ul>
    <p v-if="!this.orderData" class="noOrder">
      暂无订单详情
    </p>
  </div>
</template>

<script>
import { getOrderQuery } from 'api/api'
import NavBar from 'components/navBar.vue'

export default {
  name: 'Details',
  data () {
    return {
      orderData: {}
      // activationCode: '先天下靓号111'
    }
  },
  created () {
    console.log('参数', this.$route.params.No)
    this._getOrderQuery(this.$route.params.No)
  },
  methods: {
    onClickLeft () {
      this.$router.push({
        path: `/placeAnOrder/${this.$store.state.orderId}`
      })
    },
    _getOrderQuery (argument) {
      getOrderQuery(argument).then(res => {
        if (res && res.code === 200) {
          this.orderData = res.result
        }
      })
    },
    onCopy (e) {
      this.$toast('复制成功');
    },
    onError () {
      this.$toast('复制失败');
    }
  },
  components: {
    NavBar
  }
}
</script>

<style lang="stylus" scoped>
  .Details
    min-height 100%
    .orderWrapper
      margin 0 13px
      padding 20px 0
      border-bottom 1px solid #F7F7F7
      .orderList
        display flex
        margin-top 17px
        .navTitle
          flex 100px
          display inline-block
          font-size 13px
          font-weight 500
          color #999
        .navContent
          font-size 13px
          font-weight 500
          color #333
        .price
          color #FF5257
        .navBottomTitle
          flex 100px
          font-size 14px
          color #333
        .navBottomContent
          color #999
    .noOrder
      text-align center
      margin-top 10%
  .topTitle
    display flex
    justify-content center
    align-items center
    padding 10px
    margin 20px
    background-color #f6f6f6
    .popupTitle
      font-size 15px
    #code
      border none
      font-weight bold
      width 100px
    .topButton
      border 1px solid red
      color red
      border-radius 5px
      font-size 12px
      margin-left 10px
  .wechat
    display flex
    align-items center
    justify-content center
    border-bottom 1px solid #f7f7f7
    // flex-direction row
    padding-bottom 20px
    .imgWrapper
      width 109px
      height 109px
      .wxImg
        width 100%
        height 100%
    .content
      margin-left 20px
      p
        font-size 13px
        padding-bottom 5px
        color #666
</style>